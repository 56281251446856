export default {
  modelInfo: '/api/v1/model/info/',
  addCompare: '/api/v1/report/compare',
  algosList: '/api/v1/model/recommendalgos', // 购买推荐算法列表
  modelAlgosList: '/api/v1/model/modelalgos', // 模型训练算法
  oneclickAlgosList: '/api/v1/model/oneclickalgos', // 一键训练算法
  getBatchSize: 'api/v1/model/batchsize', // get 获取batch_size 输入框最大输入值限制
  list: {
    // 分页查询模型列表
    url: '/api/v1/model/list',
    method: 'post'
  },
  dataList: {
    url: '/api/v1/dataset/all', // 获取所有数据集列表
    method: 'get'
  },
  add: {
    // 新增模型
    url: '/api/v1/model',
    method: 'post'
  },
  getAlgos: {
    // 获取算法列表
    url: '/api/v1/model/algos', // category 1 分类 2 回归
    method: 'post'
  },
  algoInfo: {
    // 获取算法详情
    url: '/api/v1/model/algo/', // {name}
    method: 'get'
  },
  download: {
    // 模型报告下载
    url: '/api/v1/model/download/', // /api/v1/model/download/{modelId}
    method: 'get'
  },
  detail: {
    url: '/api/v1/model/', // /{modelId} // 获取模型报告
    method: 'get'
  },
  update: {
    url: '/api/v1/model/', // /{modelId}
    method: 'put'
  },
  delPre: '/api/v1/model/pre/', // 删除模型 前置校验
  delete: {
    url: '/api/v1/model/', // /{modelId}
    method: 'delete'
  },
  validatorList: {
    url: '/api/v1/model/validator/list', // 模型推理列表
    method: 'post'
  },
  getValidatorInfo: {
    url: '/api/v1/model/validator/info/', // /{validateId} // 模型推理-验证结果查看
    method: 'get'
  },
  getValidatorPreview: {
    url: '/api/v1/model/validator/preview/', // /{validateId} // 模型推理-预览
    method: 'get'
  },
  modelValidatorAdd: {
    url: '/api/v1/model/validator', // 创建模型推理
    method: 'post'
  },
  delvalidator: '/api/v1/model/delvalidator', // 删除推理记录 post
  downloadValidator: '/api/v1/model/validator/download', // 下载验证结果 post
  errorLog: '/api/v1/model/info/validator', // 推理失败-查看原因
  reportList: '/api/v1/report/list', // 结果报告列表 post
  reportInfo: '/api/v1/report/reportinfo', //  /{reportId} 查看报告详、删除报告 get
  reportDownload: '/api/v1/report/download/', // {reportId} 下载 get
  reportDownloadRes: '/api/v1/report/download/', // +{reportId}/result 下载运行结果 get
  reportAdd: '/api/v1/report', // 创建自定义报告 post
  featureengineResult: '/api/v1/model/featureengine/info/', // {modelId}获取特征工程结果列表信息
  featureengineResultDownload: '/api/v1/model/featureengine/download/', // {modelId} get 特征工程结果下载
  downloadReportCount: '/api/v1/report/count', // 查看报告剩余次数 get
  modelRestart: '/api/v1/model/restart', // 模型重新开始
  modelStop: '/api/v1/model/cancel' // 模型终止
}
