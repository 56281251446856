const url = 'api/v1'
export default {
  roiConfig: url + '/datamgmt/dataset/roi', // 感兴趣区信息config
  dataLoginStatus: url + '/datamgmt/dataarchive/', // +{user_id} get 用户登录时判断归档状态
  dataRecover: url + '/datamgmt/dataarchive/recover/', // +{user_id} post 数据归档恢复
  datasetPublic: url + '/datamgmt/dataset', // get 数据集列表 post 新建
  datasetFun: url + '/datamgmt/dataset/', //  get {datasetId} // 数据集详情  put 修改数据集
  demoDatasetDetail: url + '/datamgmt/offical/dataset/', //  get {datasetId} // 获取示例数据集详情
  datasetDel: url + '/datacenter/dataset/', //  {datasetId}  delete 删除 // 不走统一数据中心删除
  specifyType: url + '/datacenter/clinic/specifytype/', //  {datasetId}  put 临床数据集-指定类型
  getIncommonuse: url + '/datacenter/datasets/incommonuse', // get 获取常用数据集
  checksubdataset: url + '/datacenter/multimodal/checksubdataset/', // {multimodalId} get 校验是否能添加多模态子集
  getSpecifyType: url + '/datamgmt/dataset/specified_type', // get 获取临床数据集-指定类型
  datasetAddSuccess: url + '/datacenter/dataset/add', // 调用数据中心上传数据集返回成功之后调用此接口
  positiveClassList: url + '/datamgmt/dataset/', // {datasetId}/positiveclass 获取正例列表
  datasetUpload: url + '/datamgmt/dataset/upload', //  post 数据集上传获取上传文件地址url (获取minio url)
  edaInfo: url + '/datamgmt/dataset/', //  get  +{dataset_id}/statistics
  clinicInfo: url + '/datamgmt/dataset/clinicinfo', //  get 临床数据-查看 key的信息
  featuresDataView: url + '/datamgmt/dataset/table/', //  get  + {dataset_id} 特征数据集-预览
  datasetErrlog: url + '/datamgmt/dataset/err_log/', //  get  + {dataset_id} 数据集 错误日志查询

  multimodalPublic: url + '/datacenter/multimodal/', //  {multimodalId} 获取多模态数据信息 get 删除多模态 delete 修改 put
  multimodalSubdatasetDel: url + '/datacenter/multimodal/', //  {multimodalId}/dataset/{datasetId} 删除多模态子数据集 delete
  multimodalSubAdd: url + '/datacenter/multimodal', // 新增多模态子数据集 post
  multimodalListAdd: url + '/datamgmt/multimodal', // 多模态数据集列表get  新建post
  multimodalUpload: url + '/datamgmt/multimodal/upload', // 多模态数据集新建-上传因变量标签Url post
  multimodalDetail: url + '/datamgmt/multimodal/', // 多模态数据集详情 {multimodal_id} get
  multimodalEda: url + '/datamgmt/multimodal/', // {multimodal_id}/chart 多模态因变量标签EDA查看 get
  rerunEda: url + '/datamgmt/dataset/rerun_eda/' // {datasetid} PUT 发布新版本后，数据集需要重新更新EDA
}
