import Vue from 'vue'
import axios from 'axios'
import core from '@/utils/core'
import msg from '@/const/msg'
import { Message } from 'element-ui'

const request = {
  // get请求(直接返回结果)
  async getData(path, params, hint = true) {
    const awaitRetData = await request.get(path, params, hint, 'data')
    return awaitRetData
  },
  // post请求(直接返回结果)
  async postData(path, params, hint = true) {
    const awaitPostReulst = await request.post(path, params, hint, 'data')
    return awaitPostReulst
  },
  // get请求(直接返回code编码)
  async getCode(path, params, hint = true) {
    const awaitGetCode = await request.get(path, params, hint, 'code')
    return awaitGetCode
  },
  // post请求(直接返回结果,失败不提示)
  async postCode(path, params, hint = true) {
    const awaitPostCode = await request.post(path, params, hint, 'code')
    return awaitPostCode
  },
  async update(path, params, hint, result) {
    // 如果直接调用get方法,type默认为false
    if (!hint && !result) {
      hint = false
    }
    const config = { path, params, hint, result }
    config.method = 'put'
    const awaitGet = await request.request(config)
    return awaitGet
  },
  async delete(path, params, hint, result) {
    // 如果直接调用get方法,type默认为false
    if (!hint && !result) {
      hint = false
    }
    const config = { path, params, hint, result }
    config.method = 'delete'
    const awaitGet = await request.request(config)
    return awaitGet
  },
  // get请求
  async get(path, params, hint, result) {
    // 如果直接调用get方法,type默认为false
    if (!hint && !result) {
      hint = false
    }
    const config = { path, params, hint, result }
    config.method = 'get'
    const awaitGet = await request.request(config)
    return awaitGet
  },
  // post请求
  async post(path, params, hint, result) {
    // 如果直接调用post方法,type默认为false
    if (!hint && !result) {
      hint = false
    }
    const config = { path, params, hint, result }
    config.method = 'post'
    const awaitPost = await request.request(config)
    return awaitPost
  },
  // 上传文件
  async upFile(path, params, hint) {
    const config = { path, params, hint }
    config.method = 'post'
    config.headers = {
      'Content-Type': 'multipart/form-data'
    }
    const awaitUpFile = await request.request(config)
    return awaitUpFile
  },
  async upFileProgress(path, params, onUploadPorgress = {}) {
    return axios({
      url: path,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: params,
      ...onUploadPorgress
    })
  },
  async upFileOSS(path, params, contentHeader, onUploadPorgress = {}) {
    return axios({
      url: path,
      method: 'PUT',
      headers: { 'Content-Type': contentHeader, Authorization: 'none' },
      data: params,
      ...onUploadPorgress
    })
  },
  download(path, data, method = 'get') {
    return axios({
      url: path,
      method: method,
      responseType: 'blob', // 告诉服务器想到的响应格式
      headers: {
        'Content-Type': 'application/json; application/octet-stream'
      },
      params: data
    })
  },
  // 发起请求
  request(config) {
    const { path, method, params, hint, result } = config
    config.url = path
    if (method === 'post' || method === 'put') {
      config.data = params
      delete config.params
    }
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {
      try {
        const ret = await axios(config)
        const data = ret?.data
        const type = core.isSuccess(data) ? 'success' : 'error'
        // 当查询成功,并且不是get请求才显示消息提示
        if (type === 'success') {
          if (method !== 'get') {
            // 消息提示
            this.message(hint, data.msg, type)
          }
        } else {
          // 消息提示
          this.message(hint, data.msg, type)
        }
        // 结果处理
        this.resultHandle(resolve, data, result)
      } catch (err) {
        // 消息提示
        this.message(hint, '服务器连接失败!', 'error')
        if (result === 'data') {
          resolve()
        } else {
          reject(err)
        }
      }
    })
  },
  // 结果处理
  resultHandle(resolve, ret, result) {
    if (core.isSuccess(ret)) {
      if (result === 'code') {
        resolve(true)
      } else if (result === 'data') {
        const data = ret.data
        resolve({ data })
      } else {
        resolve(ret)
      }
    } else {
      // reslove
      if (result === 'code') {
        resolve(ret.code)
      } else if (result === 'data') {
        resolve('')
      } else {
        resolve(ret)
      }
    }
  },
  // 消息提示
  message(hint, message = msg.exception, type) {
    const notHintArr = [false, 'silence', undefined] // 此数组中的类型,失败不会提示
    // 消息提示
    if (!notHintArr.includes(hint)) {
      Message.closeAll()
      Message({ type, message })
    }
  }
}
for (const item in request) {
  if (item.indexOf('get') >= 0 || item.indexOf('post') >= 0) {
    Vue.prototype[`$${item}`] = request[item]
  }
}
Vue.prototype.$upFile = request.upFile
Vue.prototype.$upFileProgress = request.upFileProgress
Vue.prototype.$upFileOSS = request.upFileOSS
Vue.prototype.$update = request.update
Vue.prototype.$delete = request.delete
Vue.prototype.$download = request.download

export default request
