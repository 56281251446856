import { getToken, setToken, removeToken, setCurrentToken } from '@/utils/auth'
import axios from 'axios'

function refreshToken(data) {
  return axios({
    url: 'api/v1/user/token/refresh',
    method: 'post',
    data
  })
}
const state = {
  token: getToken(),
  language: '',
  userId: '',
  email: '',
  username: '',
  avatar: '',
  messageTotal: 0,
  alertList: [],
  userStatusData: {},
  authorityMenus: [], // 用户权限
  isFirstLogin: false,
  hasBasicMember: false, // 用户是否有基础会员？
  hasSeniorMember: false, // 用户是否有高级会员？
  activity: '', // 活动标识
  subjectType: '', // 活动课程购买标识
  group_buy_id: '' // 团购id
}

const mutations = {
  // 更新菜单路由
  saveAuthorityMenus(state, arr) {
    state.authorityMenus = arr
  },
  // 清除菜单路由
  removeAuthorityMenus(state) {
    state.authorityMenus = []
  },
  setUserId(state, val) {
    state.userId = val
  },
  setAvatar(state, val) {
    state.avatar = val
  },
  setEmail(state, email) {
    state.email = email
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  saveUserStatus(state, obj) {
    state.userStatusData = obj
  },
  setMessageTotal(state, val) {
    state.messageTotal = val
  },
  setAlertList(state, val) {
    state.alertList = val
  },
  setIsFirstLogin(state, val) {
    state.isFirstLogin = val
  },
  setResourceInfo(state, { hasBasicMember, hasSeniorMember }) {
    state.hasBasicMember = hasBasicMember
    state.hasSeniorMember = hasSeniorMember
  },
  setActivity(state, val) {
    state.activity = val
  },
  setSubjectType(state, val) {
    state.subjectType = val
  },
  setGroupBuyId(state, val) {
    state.group_buy_id = val
  }
}

const actions = {
  // 前端登出
  logout({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve, reject) => {
      refreshToken({})
        .then(response => {
          const data = response.data.data
          removeToken()
          commit('SET_TOKEN', data.refresh_token)
          setToken(data.refresh_token)
          setCurrentToken(data.refresh_token)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 获取用户的资源信息
  getResource({ commit }, data = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'api/v1/order/buyed/services',
        method: 'GET',
        data
      })
        .then(response => {
          const data = response.data.data || []
          const hasBasicMember =
            data.filter(
              item =>
                item.compute_service_id === 1 &&
                !(item.deadline_cn === '未购买' || item.deadline_cn === '已过期')
            ).length !== 0
          const hasSeniorMember =
            data.filter(
              item =>
                item.compute_service_id === 2 &&
                !(item.deadline_cn === '未购买' || item.deadline_cn === '已过期')
            ).length !== 0
          commit('setResourceInfo', { hasBasicMember, hasSeniorMember })
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
