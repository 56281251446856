import Vue from 'vue'
import Router from 'vue-router'

import system from './modules/system' // 系统（wjh）
import FreeForMembersRoutes from './modules/FreeForMembers'
Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    ...system, // 系统
    ...FreeForMembersRoutes
  ]
})
