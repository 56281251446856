import axios from 'axios'

const state = {
  reportData: {},
  config: {},
  baseCatalogue: [
    {
      label: '报告封面',
      componentName: 'ReportCover',
      class: 1,
      type: 'fullProcess'
    },
    {
      label: '模型指标',
      componentName: 'ModelMetrics',
      class: 1
    },
    {
      label: 'Summary',
      componentName: 'SummaryTitle',
      class: 1,
      type: 'fullProcess'
    },
    {
      label: '模型设置',
      componentName: 'Summary',
      class: 2
    },

    {
      label: 'AutoML自动化调参',
      componentName: 'AutoML_t1',
      class: 1,
      type: 'fullProcess'
    },
    {
      label: 'AutoML调参过程曲线',
      componentName: 'AutoML',
      class: 2
    },
    {
      label: 'AutoML调参过程列表',
      componentName: 'AutoMLList',
      class: 2
    },
    {
      label: 'Cross Validation',
      componentName: 'CrossValidation_t1',
      class: 1,
      type: 'fullProcess'
    },
    {
      label: '交叉验证',
      componentName: 'CrossValidation_t2',
      class: 2,
      type: 'fullProcess'
    },
    {
      label: '交叉验证结果比较',
      componentName: 'CrossValidation',
      class: 2
    },
    {
      label: '交叉验证学习曲线',
      componentName: 'CrossValidationLC',
      class: 2
    },
    {
      label: 'ROC-AUC',
      componentName: 'ROCAUC',
      class: 1
    },
    {
      label: 'PRC',
      componentName: 'PRC',
      class: 1
    },
    {
      label: 'Threshold Cut-off',
      componentName: 'ThresholdCutOff',
      class: 2
    },
    {
      label: 'Retrain Model',
      componentName: 'RetrainModel_t1',
      class: 1,
      type: 'fullProcess'
    },
    {
      label: 'Retrain Model介绍',
      componentName: 'RetrainModel_t2',
      class: 2,
      type: 'fullProcess'
    },
    {
      label: 'Retrain ROC曲线',
      componentName: 'RetrainModel',
      class: 2
    },
    {
      label: 'Retrain PRC曲线',
      componentName: 'RetrainModelPRC',
      class: 2
    },
    {
      label: 'DCA 曲线',
      componentName: 'DCA',
      class: 1
    },
    {
      label: 'Calibration Curve（校准曲线）',
      componentName: 'CalibrationCurve',
      class: 1
    },
    {
      label: '模型预测概率分布图',
      componentName: 'ModelPrediction',
      class: 1
    },
    {
      label: '混淆矩阵',
      componentName: 'ConfusionMatrix',
      class: 1
    },
    // {
    //   label: '模型对比雷达图',
    //   componentName: 'ModelCompare',
    //   class: 1
    // },
    {
      label: '列线图',
      componentName: 'Nomogram',
      class: 1
    },
    {
      label: '特征重要性',
      componentName: 'CharacteristicContributionDegree',
      class: 1
    },
    {
      label: '大模型建议',
      componentName: 'LargeModel',
      class: 1,
      type: 'fullProcess'
    }
  ],
  reportCatalogue: [],
  reportContentList: [],
  editable: false,
  isCustom: false,
  reportComponentName: 'FullProcessReport',
  customContent: []
}
const mutations = {
  SET_REPORT_DATA(state, data) {
    state.reportData = data
  },
  SET_REPORT_CONFIG(state, config) {
    state.config = config
  },
  SET_REPORT_CATALOGUE(state, reportCatalogue) {
    state.reportCatalogue = reportCatalogue
  },

  SET_REPORT_CONTENT_LIST(state, list) {
    state.reportContentList = list
  },
  SET_EDITABLE(state, editable) {
    state.editable = editable
  },
  SET_REPORT_IS_CUSTOM(state, bool) {
    state.isCustom = bool
  },
  SET_REPORT_COMPONENT_NAME(state, str) {
    state.reportComponentName = str
  },
  SET_CUSTOM_COMPONENT(state, arr) {
    state.customContent = arr
  }
}

const actions = {
  /**
   * Store full process report data
   * Store full process report Config
   * Init both full process report and custom report catalogue
   * Init full process report content and custom report content and editable custom report content
   */
  setReportData({ state, commit, dispatch }, data) {
    commit('SET_REPORT_DATA', data)
    if (state.reportComponentName === 'FullProcessReport') {
      const configObj = {
        multi_class: data.multi_class,
        ...data.config
      }
      commit('SET_REPORT_CONFIG', configObj)
      dispatch('initCatalogue')
    }

    dispatch('initReportContent')
  },

  /**
   * init catalogue
   */
  initCatalogue({ state, commit, dispatch }) {
    let catalogue = JSON.parse(JSON.stringify(state.baseCatalogue))

    if (state.isCustom) {
      catalogue = catalogue.filter((item) => item.type !== 'fullProcess')
    }

    if (state.config.is_ml) {
      // 机器学习 删除交叉验证学习曲线
      catalogue = catalogue.filter(
        (item) => item.componentName !== 'CrossValidationLC'
      )
    } else {
      // 深度学习 删除特征重要性
      catalogue = catalogue.filter(
        (item) => item.componentName !== 'CharacteristicContributionDegree'
      )
    }

    if (!state.config.is_automl) {
      // 非自动调参 删除自动调参的两个
      catalogue = catalogue.filter(
        (item) =>
          item.componentName !== 'AutoML_t1' &&
          item.componentName !== 'AutoML' &&
          item.componentName !== 'AutoMLList'
      )
    }

    if (!state.config.has_test) {
      // 无测试集 不展示模型预测瀑布图、混淆矩阵
      const filters = ['ModelPrediction', 'ConfusionMatrix']
      catalogue = catalogue.filter(
        (item) => !filters.includes(item.componentName)
      )
    }

    if (!state.config.has_test && !state.config.is_ml) {
      // 无测试集 && 深度学习
      const filters = [
        'RetrainModel_t1',
        'RetrainModel_t2',
        'RetrainModel',
        'RetrainModelPRC'
      ]
      catalogue = catalogue.filter(
        (item) => !filters.includes(item.componentName)
      )
    }

    if (!state.config.is_radscore) {
      // 非 radscore 删除列线图
      catalogue = catalogue.filter((item) => item.componentName !== 'Nomogram')
    }

    if (state.config.multi_class === 'multi') {
      // 多分类数据集， 排除以下表/图
      /*
        [交叉验证学习曲线,ROC-AUC,PRC,Threshold Cut-off,Retrain Model,Retrain Model介绍,
        Retrain ROC曲线,Retrain PRC曲线,DCA 曲线,Calibration Curve（校准曲线）,模型预测概率分布图， 特征重要性， 大模型建议
      */
      const filters = [
        'CrossValidationLC',
        'ROCAUC',
        'PRC',
        'ThresholdCutOff',
        'RetrainModel_t1',
        'RetrainModel_t2',
        'RetrainModel',
        'RetrainModelPRC',
        'DCA',
        'CalibrationCurve',
        'ModelPrediction',
        'CharacteristicContributionDegree',
        'LargeModel'
      ]
      catalogue = catalogue.filter(
        (item) => !filters.includes(item.componentName)
      )
    }

    if (state.config.is_multi) {
      // 机器学习单模态&深度单模态&机器前融合 才显示大模型建议， 其他都不现实大模型建议
      if (state.config.fusion_method !== 'early') {
        // 深度学习 删除特征重要性
        catalogue = catalogue.filter(
          (item) => item.componentName !== 'LargeModel'
        )
      }
    }

    commit('SET_REPORT_CATALOGUE', catalogue)
    return catalogue
  },

  /**
   * init report content
   */
  initReportContent({ state, commit, dispatch }) {
    if (state.isCustom) {
      // 自定义报告编辑页面
      if (state.editable) {
        commit('SET_REPORT_CONTENT_LIST', [])
      } else {
        // 自定义报告展示页面
        dispatch('getReportContentConfig')
      }
    } else {
      // 全流程报告展示页面
      let contentList = JSON.parse(JSON.stringify(state.reportCatalogue))
      const componentNameList = ['ReportCover', 'LargeModel'] // 不在自定义报告菜单显示，但是需要在全流程中显示的组件
      contentList = contentList.filter(
        (item) =>
          item.type !== 'fullProcess' || componentNameList.includes(item.componentName)
      )
      commit('SET_REPORT_CONTENT_LIST', contentList)
    }
  },

  getReportContentConfig({ state, commit }) {
    const list = state.baseCatalogue.slice(0, 2)
    commit('SET_REPORT_CONTENT_LIST', list)
  },

  getReportData({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'api/v1/user/token/refresh',
        method: 'get',
        params
      })
        .then((response) => {
          const data = response.data.data
          commit('SET_REPORT_DATA', data)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
