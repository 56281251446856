export default {
  ossurl: '/api/v1/upload/url', // ossurl
  ftpfiles: '/api/v1/dataset/ftpfiles/', // {fileCategory} // 获取ftp 文件目录
  updateradmics: '/api/v1/radiomics/updateradmics',
  list: '/api/v1/dataset/list', // 分页获取特征数据集列表
  add: '/api/v1/dataset', // 新增特征数据集
  pre: '/api/v1/dataset/pre/', // delete 数据集删除前置校验{datasetId}
  datasetPublic: '/api/v1/dataset/', // {datasetId} // 更新，删除 详情 'edit--put, delete-- delete,  detail--get'
  getEda: '/api/v1/dataset/eda/', // {datasetId} 获取特征数据集eda信息 get
  clinicaldataList: '/api/v1/dataset/eda/clinicaldata/list', // 获取临床数据列表post
  clinicaldataInfo: '/api/v1/dataset/eda/clinicaldata/info', // {datasetId} 获取临床数据列表详细信息get
  clinicaldataUpdate: '/api/v1/dataset/eda/clinicaldata/update', // 修改临床数据post
  standardization: '/api/v1/dataset/eda/standardization', // 标准化post && 获取标准化信息get
  standardizationDoing: '/api/v1/dataset/eda/standardization/doing', // post 执行标准化
  standardfields: '/api/v1/standardfields', // post 新建标准字段
  standardfieldsList: '/api/v1/standardfields/list', // post 标准字段列表
  getTable: '/api/v1/dataset/table/', // datasetId 获取特征数据前25条数据 get
  getFileList: {
    url: '/api/v1/dataset/files/', // {datasetId} 获取数据集文件列表
    method: 'get'
  },
  radiomicsInfo: {
    url: '/api/v1/dataset/radiomics/info', // radiomics 配置详情
    method: 'post'
  },
  dataRadiomicsInfo: {
    url: '/api/v1/dataset/radiomics/', // {datasetId} radiomics 配置详情
    method: 'post'
  },
  dataRadiomicsUpdate: {
    url: '/api/v1/dataset/radiomics', // radiomics 配置文件更换
    method: 'post'
  },
  upload: {
    url: '/api/v1/dataset/upload',
    method: 'post'
  },
  // 查找文件
  findfile: {
    url: '/api/v1/dataset/findfile',
    method: 'post'
  },
  // 断点续传
  bpc: {
    url: '/api/v1/dataset/bpc',
    method: 'post'
  },
  // 切片传输完成 合并切片文件
  bpcfinish: {
    url: '/api/v1/dataset/bpcfinish',
    method: 'post'
  },
  // 删除切片
  removechunk: {
    url: '/api/v1/dataset/removechunk',
    method: 'post'
  }
}
