export default {
  list: '/api/v1/dataprepare/list', // 预处理列表
  add: '/api/v1/dataprepare', // 新增、复制预处理 post
  pre: '/api/v1/dataprepare/pre/', // 删除预处理前置校验
  resume: '/api/v1/dataprepare/resume/', // 恢复预处理默认 put
  preresume: '/api/v1/dataprepare/preresume/', // 恢复预处理默认 前置校验 get
  datapreparePublic: '/api/v1/dataprepare/', // {dataprepareId} 修改名称put  删除 delete
  updataChain: '/api/v1/dataprepare/chain', // 修改链路 post
  chainlist: '/api/v1/dataprepare/options/chainlist/', // {originalId} 获取可选的链路列表 get
  start: '/api/v1/dataprepare/start', // 运行预处理
  cancel: '/api/v1/dataprepare/cancel', // 终止预处理
  getEda: '/api/v1/dataprepare/eda/', // {dataprepareId} 获取预处理EDA
  errMsg: 'api/v1/dataprepare/' // {dataprepareId} + /errmsg  查看预处理失败原因
}
