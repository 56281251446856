export default {
  resources: 'api/v1/price/discount/resources', // 资源列表（会员价格 && 优惠券列表）
  getPriceCount: 'api/v1/price/discount', // get 通过 （套餐*时长*折扣）获取 （ 总价、折扣价、实付价格
  getPriceList: 'api/v1/price/discount/desc/batch', // post 获取折扣梯度信息列表
  orderAdd: 'api/v1/order', // post 创建订单
  orderList: 'api/v1/order/list', // 订单列表 post
  orderInfo: 'api/v1/order/info/', // 订单详情 {orderId} get
  orderPay: 'api/v1/order/pay', // 订单支付 post
  preparepay: 'api/v1/order/preparepay', // 订单预支付 post
  payStatusQuery: 'api/v1/order/query', // 查询是否完成支付
  orderClose: 'api/v1/order/close', // 订单关闭 post
  serviceUsedLog: 'api/v1/order/service/used', // 订单使用记录  post
  buyedServices: 'api/v1/order/buyed/services', // 已购买的套餐 get
  recommendBuy: 'api/v1/order/recommend', // 单模态-购买推荐 get
  multirecommendBuy: 'api/v1/order/multirecommend', // 多模态-购买推荐 get
  refundList: 'api/v1/refund/list', // 退费列表 get
  invoiceList: 'api/v1/order/invoice/list', // 发票管理列表 get
  applyInvoice: 'api/v1/invoice/apply', // 申请发票 post
  invoiceInfo: 'api/v1/invoice/info', // 发票详情 get
  freeget: 'api/v1/order/freeget', // 免费领取高级会员 post 领取试用， get 查询是否领取过
  groupBuyCreate: 'api/v1/group_buy/create', // 拼团-创建 POST
  groupBuyList: 'api/v1/group_buy/list', // 拼团-列表  GET
  groupBuyListMine: 'api/v1/group_buy/list/mine', // 我的拼团-列表  GET
  groupBuyInfo: 'api/v1/group_buy/', // 拼团详情  GET
  contact: 'api/v1/contact' // 联系我们  POST
}
