import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
import getters from './getters'
import { removeToken } from '@/utils/auth'
Vue.use(Vuex)

// 动态获取模块文件
const files = require.context('./modules', false, /\.js$/)
const modules = {}
files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
export default new Vuex.Store({
  modules,
  getters,
  plugins: [
    persistedState({
      key: 'vuex',
      storage: window.sessionStorage,
      reducer(val) {
        if (!val.user.token) {
          removeToken()
          // return empty state when user logged out
          return {}
        }
        return val
      }
    })
  ]
})
