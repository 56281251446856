import router from '@/router'
import store from '@/store'
// import { Message } from 'element-ui'
import { getToken, removeToken, getCurrentToken } from '@/utils/auth'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

NProgress.configure({
  showSpinner: false
}) // NProgress Configuration

// 全局路由前置守卫
router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  const path = to.path

  // 判断是否登录
  const token = getToken()
  const currentToken = getCurrentToken()
  const pathAuth = [
    '/pages/system/login',
    '/register',
    '/retrievePsd',
    '/platformAgreement'
  ]
  if (!token && !currentToken && !pathAuth.includes(path)) {
    // Message.error('未登录，请登录后再访问!')
    localStorage.clear()
    sessionStorage.clear()
    removeToken()
    next('/pages/system/login')
    NProgress.done()
  } else {
    // 通知menuTabs路由变化
    store.commit('menuTabs/routerChange', to)
    // resolve钩子

    const pathList = ['/pages/system/login', '/register', '/retrievePsd']
    if ((token || currentToken) && pathList.includes(path)) {
      // 跳转登录页时判断是否需要重定向（为了和移动端保持一致，没有使用 redirect 登录后自动重定向）
      const ROUTER_QUERY = to.query
      const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object
      }
      if (path === '/pages/system/login' && !isEmptyObject(ROUTER_QUERY)) {
        const groupBuyId = ROUTER_QUERY.group_buy_id
        const subjectType = ROUTER_QUERY.subjectType
        if (subjectType) {
          const host = window.location.host
          let activityUrl = ''
          if (host.includes('qtestcloud')) {
            activityUrl =
              'https://qtestactivity.raymics.com/#/buySubject?subType=' +
              subjectType
          } else if (host.includes('testcloud')) {
            activityUrl =
              'https://testactivity.raymics.com/#/buySubject?subType=' +
              subjectType
          } else {
            activityUrl =
              'https://activity.raymics.com/#/buySubject?subType=' + subjectType
          }

          window.location.replace(activityUrl)
        } else if (groupBuyId) {
          store.commit('guide/setPopupClosed', true)
          next('/purchaseServices?group_buy_id=' + groupBuyId)
        } else {
          next('/')
        }
      } else {
        next(from.path)
      }
      NProgress.done()
    } else {
      next()
    }
  }
})
// 全局路由后置钩子
router.afterEach((to, from) => {
  // ui自适应初始化
  NProgress.done() // finish progress bar
})
