<template>
  <div>
    <xnr-dialog
      title="失败原因"
      :visible="visiableError"
      width="800px"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form class="data-dialog-form" size="large">
        <el-form-item>
          {{ errorInfo.errorMsg }}
        </el-form-item>
        <el-form-item v-if="isJSON(logText) && logText">
          <!-- <div style="background: black; padding: 10px">
            <pre v-text="logText" style="white-space: pre-wrap"></pre>
          </div> -->
          <div class="err-content">
            <p>{{ logMsg }}</p>
            <p>{{ logDescription }}</p>
          </div>
          <div class="m-t-10" style="color: #262f54">错误代码： {{ logCode }}</div>
          <p class="a-link" @click="handleClickViewRes">查看错误代码解决方案</p>
        </el-form-item>
        <el-form-item v-else>
          {{ logText }}
        </el-form-item>
      </el-form>
      <div slot="footer-content" class="dialog-footer">
        <el-row class="toolbar footer">
          <!-- <span class="a-link f-l" v-if="sendError" @click="handleClickSend"
            >发送反馈至Raymics</span
          > -->
          <el-button
            v-if="errorInfo.taskType === 2"
            @click="showModelConfig"
            type="primary"
            size="large"
            >查看参数</el-button
          >

          <el-button @click="handleClose" type="primary" size="large"
            >确定</el-button
          >
        </el-row>
      </div>
    </xnr-dialog>
  </div>
</template>
<script>
// import apiCode from '@/api/apiCode'

export default {
  props: {
    visiableError: {
      type: Boolean,
      default: false
    },
    base64: {
      type: Boolean,
      default: false
    },
    errorInfo: {
      type: Object,
      default: () => {}
    },
    sendError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visiableLog: false,
      logText: '',
      logMsg: '',
      logDescription: '',
      logCode: ''
    }
  },
  created() {
    if (this.errorInfo && this.errorInfo.errorLog && this.base64) {
      const Base64 = require('js-base64').Base64
      this.logText = Base64.decode(this.errorInfo.errorLog)
      if (this.isJSON(this.logText)) {
        const jsonLog = JSON.parse(this.logText) || {}
        this.logMsg = jsonLog.msg
        this.logDescription = jsonLog.guidance
        this.logCode = String(jsonLog.error_code)
      }
    } else {
      this.logText = this.errorInfo?.errorLog || ''
    }
  },
  methods: {
    isJSON(str) {
      try {
        JSON.parse(str)
      } catch {
        return false
      }
      return true
    },
    handleClickViewRes() {
      window.open(
        '#/docsDescription?name=错误代码&subName=' + this.logCode,
        '_blank'
      )
    },
    // async handleClickSend() {
    //   // this.errorInfo.taskId
    //   //       // "任务类型对应的id。预处理ID，模型训练id，推理id，特征工程id，数据集id"
    //   //    this.errorInfo.taskType
    //   //       // 任务类型 1:预处理 2:模型训练 4:模型推理 5:特征工程 6:数据集eda"
    //   const params = {
    //     task_type: this.errorInfo.taskType,
    //     id: this.errorInfo.taskId,
    //     err_msg: this.logText
    //   }
    //   const data = await this.$post(this.$api.config.errCommit, params)
    //   if (data.code === apiCode.success) {
    //     this.handleClose()
    //     const htmlText =
    //       '<p style="color: #888;">Raymics 已经收到您的反馈，感谢您的支持，如果存在疑问请咨询客服：400-019-2116</p>'
    //     this.$alert(htmlText, '反馈成功', {
    //       dangerouslyUseHTMLString: true
    //     })
    //   } else {
    //     this.$message.error(data.msg)
    //   }
    // },
    handleClose() {
      this.$emit('update:visiableError', false)
    },
    showModelConfig() {
      this.$emit('showNewModelDialog')
    },
    handleClose2() {
      this.visiableLog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.text-area.el-input--large {
  width: 100% !important;
}
.err-content {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 20px;
}
.footer .el-button {
  width: 120px;
}
.a-link {
  display: inline-block;
  line-height: 40px;
}
</style>
