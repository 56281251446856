/**
 * @example
 * :::demo
 * ```html
 * <template>
 *   <div>
 *     <p><b>验证手机号码</b> 手机号 {{mobile1}} 验证结果 {{mobile1Valid}}</p>
 *     <p><b>验证手机号码</b> 手机号 {{mobile2}} 验证结果 {{mobile2Valid}}</p>
 *   </div>
 * </template>
 * import regexp from '@/utils/regexp'
 * <script>
 *   export default {
 *     data() {
 *       return {
 *         mobile1: '19088880000',
 *         mobile2: '13088880000',
 *         mobile1Valid: '',
 *         mobile2Valid: ''
 *       }
 *     },
 *     mounted() {
 *       this.valid()
 *     },
 *     methods: {
 *       valid() {
 *         let re = regexp
 *         this.mobile1Valid = re.mobile.test(this.mobile1)
 *           ? '正常手机号'
 *           : '非法手机号'
 *         this.mobile2Valid = re.mobile.test(this.mobile2)
 *           ? '正常手机号'
 *           : '非法手机号'
 *       }
 *     }
 *   }
 * </script>
 * ```
 * :::
 *
 * ### API
 * | 名称        | 验证类型                | 正则表达式                                                                                                                                    |
 * | ----------- | ----------------------- | --------------------------------------------------------------------------------------------------------------------------------------------- |
 * | username    | 用户名                  | /^[a-zA-Z0-9]([a-zA-Z0-9_]{4,19})+\$/                                                                                                         |
 * | password    | 密码                    | /^[a-zA-Z0-9]{6,16}\$/                                                                                                                        |
 * | mobile      | 手机号码                | /^1\d{10}\$/                                          |
 * | tel         | 固定电话                | /^0\d{2,3}-\d{7,8}(-\d{3,5}){0,1}\$/                                                                                                          |
 * | phone       | 固话+手机【支持分机号】 | /^(0\d{2,3}-\d{7,8}(-\d{3,5}){0,1})&#124;(((13[0-9])&#124;(14[5&#124;7])&#124;(15([0-3]&#124;[5-9]))&#124;(1[6&#124;7&#124;8][0-9]))\d{8})\$/ |
 * | identity    | 身份证                  | /^\d{6}(18&#124;19&#124;20)?\d{2}(0[1-9]&#124;1[012])(0[1-9]&#124;[12]\d&#124;3[01])\d{3}(\d&#124;X)\$/                                       |
 * | bankAccount | 银行卡号                | /^([1-9]{1})(\d{15}&#124;\d{16}&#124;\d{18})\$/                                                                                               |
 * | currency    | 浮点型(保留两位小数)    | /^(([1-9]\d\*)&#124;0)(\.\d{0,2})?\$/                                                                                                         |
 * | quantity    | 浮点型                  | /^(([1-9]\d*)&#124;0)(\.\d*)?\$/                                                                                                              |
 * | posInt      | 正整数                  | /^[1-9]\d\*\$/                                                                                                                                |
 * | negInt      | 负整数                  | /^-[1-9]\d\*\$/                                                                                                                               |
 * | staffNumber | 员工工号                | /^[0-9A-Za-z]{1,6}\$/                                                                                                                         |
 * | ipAddress   | ip 地址                 | /^((25[0-5]&#124;2[0-4]\d&#124;((1\d{2})&#124;([1-9]?\d)))\.){3}(25[0-5]&#124;2[0-4]\d&#124;((1\d{2})&#124;([1-9]?\d)))\$/                    |
 * | trim        | 删除两端空格            | /(^\s*)&#124;(\s*\$)/                                                                                                                         |
 * | ltrim       | 删除左边空格            | /(^\s\*)/                                                                                                                                     |
 * | rtrim       | 删除右边空格            | /(\s\*\$)/                                                                                                                                    |
 */
const regexp = {
  // username: /^[a-zA-Z0-9]([a-zA-Z0-9_]{0,24})+$/,
  username: /^[\u4e00-\u9fa5A-Za-z0-9_-]+$/, // 用户名
  password: /^[a-zA-Z0-9]{6,16}$/, // 密码
  mobile: /^1\d{10}$/, // 手机号码
  tel: /^0\d{2,3}-\d{7,8}(-\d{3,5}){0,1}$/, // 固定电话
  phone:
    /^(0\d{2,3}-\d{7,8}(-\d{3,5}){0,1})|(((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(1[6|7|8|9][0-9]))\d{8})$/, // 固话+手机【支持分机号】
  identity:
    /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/, // 身份证
  bankAccount: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/, // 银行卡号【招商银行16，交通银行17位，其他19位，信用卡16位】
  currency: /^(([1-9]\d*)|0)(\.\d{0,2})?$/, // 浮点型(保留两位小数)
  quantity: /^(([1-9]\d*)|0)(\.\d*)?$/, // 浮点型
  quantityTwo: /^(0(.\d+)?|1(\.0+)?)$/, // 浮点型0-1 的小数
  posInt: /^[1-9]\d*$/, // 大于0整数
  posIntGt: /^(0|\+?[1-9][0-9]*)$/, // 整数0--
  posIntTwo: /^[0-1]\d*$/, // 整数0-1
  negInt: /^-[1-9]\d*$/, // 负整数
  posAndNegInt: /^(0|\+?[1-9][0-9]\d*|-1)$/, // 大于等于-1的整数
  staffNumber: /^[0-9A-Za-z]{1,6}$/, // 员工工号
  ipAddress:
    /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/, // ip地址
  trim: /(^\s*)|(\s*$)/, // 两边空格
  ltrim: /(^\s*)/, // 左边空格
  rtrim: /(\s*$)/, // 右边空格
  url: /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/, // 有效的url
  lowerCase: /^[a-z]+$/, // 小写字母
  upperCase: /^[A-Z]+$/, // 大写字母
  alphabets: /^[A-Za-z]+$/, // 大小写字母
  /* eslint-disable */
  email:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}

export default regexp
