<template>
  <div
    ref="draggable"
    class="guide"
    :style="{ bottom: `${position.bottom}px`, right: `${position.right}px` }"
  >
    <i class="el-icon-close" @click="handleClose"></i>
    <div class="video-box" @mousedown="startDrag">
      <video
        :src="require('@/assets/video/' + videoIndex + '.mp4')"
        autoplay
        loop
        disablepictureinpicture
        style="width: 100%; height: 100%"
      ></video>
    </div>
    <div class="guide-content">
      <h3 class="guide-content-title">{{ content.title }}</h3>
      <p class="guide-content-desc">{{ content.desc }}</p>
      <p class="guide-content-reference">
        <span>参阅：</span>
        <a :href="content.link" target="_blank">{{ content.linkText }}</a>
      </p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'GuideVideo',

  data() {
    return {
      position: {
        bottom: 10,
        right: 10
      },
      isDragging: false,
      dragOffset: {
        x: 0,
        y: 0
      },
      guideContents: [
        {
          title: '数据-数据上传格式',
          desc: '新增数据集，删除，查看数据集的基本信息和EDA信息',
          linkText: '数据-数据上传格式',
          link: '#/docsDescription?name=数据上传格式&subName=数据上传格式'
        },
        {
          title: '机器学习-预处理',
          desc: '什么是预处理链路，添加新链路，运行，以及调整参数。',
          linkText: '机器学习-数据预处理',
          link: '#/docsDescription?name=数据预处理&subName=机器学习-数据预处理'
        },
        {
          title: '机器学习-特征工程',
          desc: '什么是特征工程，添加新链路，运行以及查看特征工程报告。',
          linkText: '机器学习-特征工程',
          link: '#/docsDescription?name=特征工程&subName=特征工程'
        },
        {
          title: '机器学习-模型训练',
          desc: '什么是模型训练，创建模型训练，删除以及查看模型报告。',
          linkText: '机器学习-模型训练',
          link: '#/docsDescription?name=模型训练&subName=机器学习'
        },
        {
          title: '深度学习-预处理',
          desc: '添加新链路，运行以及调整参数',
          linkText: '深度学习-数据预处理',
          link: '#/docsDescription?name=数据预处理&subName=深度学习-数据预处理'
        },
        {
          title: '深度学习-模型训练',
          desc: '创建深度学习模型训练，删除以及查看模型报告。',
          linkText: '深度学习-模型训练',
          link: '#/docsDescription?name=模型训练&subName=深度学习'
        },
        {
          title: '结果报告',
          desc: '查看，下载报告及结果，对比报告。',
          linkText: '结果报告',
          link: '#/docsDescription?name=结果报告&subName=结果报告'
        }
      ]
    }
  },

  computed: {
    ...mapState('guide', {
      showGuideVideo: 'showGuideVideo',
      guideIndex: 'guideIndex',
      videoIndex: 'videoIndex'
    }),
    content() {
      return this.guideContents[this.guideIndex]
    }
  },

  mounted() {
    window.addEventListener('resize', this.onWindowResize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize)
  },

  methods: {
    handleClose() {
      this.$store.commit('guide/setShowGuideVideo', false)
    },

    startDrag(event) {
      const draggableElement = this.$refs.draggable
      this.isDragging = true
      this.dragOffset.x =
        event.clientX -
        (window.innerWidth - this.position.right - draggableElement.offsetWidth)
      this.dragOffset.y =
        event.clientY -
        (window.innerHeight -
          this.position.bottom -
          draggableElement.offsetHeight)
      document.addEventListener('mousemove', this.onDrag)
      document.addEventListener('mouseup', this.endDrag)
    },

    onDrag(event) {
      if (this.isDragging) {
        const draggableElement = this.$refs.draggable
        let newRight =
          window.innerWidth -
          event.clientX -
          draggableElement.offsetWidth +
          this.dragOffset.x
        let newBottom =
          window.innerHeight -
          event.clientY -
          draggableElement.offsetHeight +
          this.dragOffset.y

        // 防止元素与窗口顶部和左侧交叉，并设置最小边距
        const minRight = 0 // 允许右边靠近窗口边缘
        const minBottom = 0 // 允许下边靠近窗口边缘
        const maxRight = window.innerWidth - draggableElement.offsetWidth // 允许右边靠近窗口边缘
        const maxBottom = window.innerHeight - draggableElement.offsetHeight // 允许下边靠近窗口边缘

        newRight = Math.max(newRight, minRight)
        newRight = Math.min(newRight, maxRight)
        newBottom = Math.max(newBottom, minBottom)
        newBottom = Math.min(newBottom, maxBottom)

        // 设置最小左侧和顶部距离
        if (event.clientX - this.dragOffset.x < 64) {
          newRight = window.innerWidth - draggableElement.offsetWidth - 64
        }
        if (event.clientY - this.dragOffset.y < 60) {
          newBottom = window.innerHeight - draggableElement.offsetHeight - 60
        }

        this.position.right = newRight
        this.position.bottom = newBottom
      }
    },

    endDrag() {
      this.isDragging = false
      document.removeEventListener('mousemove', this.onDrag)
      document.removeEventListener('mouseup', this.endDrag)
    },

    onWindowResize() {
      const draggableElement = this.$refs.draggable
      const maxRight = window.innerWidth - draggableElement.offsetWidth
      const maxBottom = window.innerHeight - draggableElement.offsetHeight

      if (this.position.right > maxRight - 64) {
        this.position.right = maxRight - 64
      }
      if (this.position.bottom > maxBottom - 64) {
        this.position.bottom = maxBottom - 60
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.guide {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 352px;
  height: 350px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: 5px;
    color: #ffffff;
    cursor: pointer;
    z-index: 1001;
  }
  .video-box {
    height: 198px;
    background: #ffffff;
    cursor: move;
  }

  &-content {
    padding: 20px 20px 30px;
    &-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #262f54;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      margin-bottom: 10px;
    }
    &-desc {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #888888;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 10px;
    }
    &-reference {
      span {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #888888;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
      a {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #2f96f1;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
    }
  }
}
</style>
