export default {
  list: 'api/v1/config/list', // 获取配置文件 --字典
  lawList: 'api/v1/law/list', // 获取法律文件列表
  weblist: 'api/v1/config/weblist', // 公共URL地址config-- 社区、官网
  feedback: 'api/v1/feedback', // 反馈提交 post
  feedbackapp: 'api/v1/feedback/app', // 体验反馈提交 post
  feedbackUrl: 'api/v1/datamgmt/feedback/file', // feedback url
  errCommit: 'api/v1/feedback/errcommit', // post 任务失败原因反馈
  questionList: 'api/v1/question/list', // get 首页问题列表
  noticeInfo: 'api/v1/notice/info', // get 公告
  noticeOutinfo: 'api/v1/notice/outinfo', // get 公告-未登录-在登录页面展示
  videoList: 'api/v1/video/list', // get 首页视频链接列表
  popinfo: 'api/v1/notice/popinfo', // 登录强制弹窗信息 根据ID判断是否展示弹窗
  popConfirm: 'api/v1/notice/confirm/', //  更新强制弹窗状态
  guide: 'api/v1/config/guide', //  引导弹窗 GET
  updateGuide: 'api/v1/config/guide', //  更新引导信息 PUT
  hasGroupBuy: 'api/v1/group_buy/has' //  是否有活动？ GET
}
