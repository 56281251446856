const state = {
  firstViewProInfoFlg: false, // 记录用户第一次查看项目详情时，做项目引导流程标记
  showTips: true, // 记录用户第一次注册登录后，进入首页进行 新手引导
  taskFlg: '',
  projectData: {},
  peojectTabActive: 1,
  peojectTrainTabActive: 1,
  activeFlowTab: '',
  activePageTabVal: '',
  detailQueryTime: '',
  projectFlowCollapse: false,
  modelData: [],
  currentStep: 1,
  buyType: 1, // 购买服务类型 1 运算服务  2 存储空间
  serviceCurrentStep: 1,
  usedSpacePercent: 0,
  modelType: 1, // 1 单模态 2 前融合多模态 3 后融合多模态
  modelTrainInfo: {}, // 模型训练详情-部分信息
  activeMenuObj: {} // 项目详情-记录选中tab
}
const mutations = {
  setFirstViewProInfo(state, val) {
    state.firstViewProInfoFlg = val
  },
  changeTipsStatus(state, val) {
    state.showTips = val
  },
  changeTaskFlg(state, val) {
    state.taskFlg = val
  },
  changeProjectData(state, val) {
    state.projectData = val
  },
  setDetailActiveMenu(state, val) {
    state.activeMenuObj = val
  },
  setProjectFlowCollapse(state, val) {
    state.projectFlowCollapse = val
  },
  setModelType(state, val) {
    state.modelType = val
  },
  setModelTrainInfo(state, val) {
    state.modelTrainInfo = val
  },
  setDetailQueryTime(state, val) {
    state.detailQueryTime = val
  },
  changeProjectTab(state, val) {
    state.peojectTabActive = val
  },
  changeProjectTrainTab(state, val) {
    state.peojectTrainTabActive = val
  },
  changeActivePageTabVal(state, val) {
    state.activePageTabVal = val
  },
  changeActiveFlowTab(state, val) {
    state.activeFlowTab = val
  },
  changeModelData(state, val) {
    state.modelData = val
  },
  changeCurrentStep(state, val) {
    state.currentStep = val
  },
  changeBuyType(state, val) {
    state.buyType = val
  },
  changeServiceCurrentStep(state, val) {
    state.serviceCurrentStep = val
  },
  changeUsedSpace(state, val) {
    state.usedSpacePercent = val
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
