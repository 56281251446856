const msg = {
  saveSuccess: '保存成功!',
  updateSuccess: '修改成功!',
  deleteSuccess: '删除成功!',
  notSave: '您还有未保存的数据!',
  connectionFail: '服务器连接失败!',
  exception: '系统异常!'
}

export default msg
