const Home = () => import('@/views/home/index')
const TopHeader = () => import('@/views/home/topHeader')
const notFound = () => import('@/views/system/404') // 404
const login = () => import('@/views/system/login') // 登录
const Register = () => import('@/views/system/register') // 注册
const RetrievePsd = () => import('@/views/system/retrievePsd') // 找回密码
const UserInfo = () => import('@/views/system/userInfo') // 个人中心-修改密码
const AccountMn = () => import('@/views/userMn/index') // 账号管理

const OrderCenter = () => import('@/views/orderCenter/list') // 订单管理
const OrderPay = () => import('@/views/orderCenter/orderPay') // 订单支付
const RefundList = () => import('@/views/orderCenter/refund/index') // 发票管理列表
const InvoiceList = () => import('@/views/orderCenter/invoice/list') // 发票管理列表
const InvoiceDetail = () => import('@/views/orderCenter/invoice/detail') // 发票详情
const InvoiceAdd = () => import('@/views/orderCenter/invoice/add') // 申请发票
const MyGroupBuy = () => import('@/views/orderCenter/myGroupBuy.vue') // 我的拼团
const PurchaseServices = () => import('@/views/purchaseServices/index') // 购买服务

const Coupon = () => import('@/views/coupon/index') // 优惠券
const CouponCenter = () => import('@/views/coupon/couponCenter') // 领券中心

const Dashboard = () => import('@/views/dashboard/index') // 首页
const Project = () => import('@/views/project/index') // 我的项目
const ProjectPages = () => import('@/views/project/pages') // 我的项目
const ProjectPagesList = () => import('@/views/project/pages/list') // 我的项目
const ProjectPagesDetail = () => import('@/views/project/pages/detail') // 我的项目
const MySpace = () => import('@/views/project/mySpace') // 存储空间
const MsgCenter = () => import('@/views/msgCenter') // 存储空间
const UsageRecord = () => import('@/views/project/usageRecord') // 使用记录

const ResultReportDetail = () =>
  import('@/views/project/resultReports/resultReportDetail') // 结果报告查看

const DataMn = () => import('@/views/dataMn/index') // 数据管理
const DataPage = () => import('@/views/dataMn/dataPage') // 数据管理
const DataPageList = () => import('@/views/dataMn/dataPage/list') // 数据管理列表
const DataPageDetail = () => import('@/views/dataMn/dataPage/detail') // 数据管理详情

const SignedProject = () => import('@/views/signedProject/index')

const DocsDescription = () => import('@/views/docsDescription/index') // 操作文档--使用说明链接页面
const PlatformAgreement = () => import('@/views/platformAgreement/index') // 平台法律声明--协议链接页面

const WechatPay = () => import('@/views/payPage/wechatPay') // 微信支付
const AliPay = () => import('@/views/payPage/aliPay') // 微信支付

const system = [
  {
    path: '*',
    component: notFound
  },
  {
    path: '/pages/system/login',
    component: login
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/retrievePsd',
    component: RetrievePsd
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        component: Dashboard,
        name: 'Dashboard',
        meta: {
          title: '首页',
          keepalive: true,
          breadNum: 1
        }
      },
      {
        path: '/project',
        component: Project,
        name: 'Project',
        meta: {
          title: '我的项目',
          breadNum: 1,
          keepalive: false
        },
        redirect: '/project/pages',
        children: [
          {
            path: 'pages',
            component: ProjectPages,
            redirect: '/project/pages/list',
            children: [
              {
                path: 'list',
                component: ProjectPagesList,
                meta: {
                  title: '项目列表'
                }
              },
              {
                path: 'detail',
                component: ProjectPagesDetail,
                meta: {
                  title: '项目详情'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/signedProject',
        component: SignedProject,
        name: 'SignedProject',
        meta: {
          title: '签约项目',
          keepalive: false,
          breadNum: 1
        }
      },
      {
        path: '/mySpace',
        component: MySpace,
        name: 'MySpace',
        meta: {
          title: '存储空间',
          breadNum: 1
        },
        children: []
      },
      {
        path: '/usageRecord',
        component: UsageRecord,
        name: 'UsageRecord',
        meta: {
          title: '使用记录',
          breadNum: 1
        },
        children: []
      },

      {
        path: '/msgCenter',
        component: MsgCenter,
        name: 'MsgCenter',
        meta: {
          title: '消息中心',
          breadNum: 1
        },
        children: []
      },
      {
        path: '/resultReportDetail',
        component: ResultReportDetail,
        name: 'ResultReportDetail',
        meta: {
          title: '结果报告查看',
          breadNum: 1
        },
        children: []
      },
      {
        path: '/dataMn',
        component: DataMn,
        name: 'DataMn',
        meta: {
          title: '数据管理',
          breadNum: 1,
          keepalive: false
        },
        redirect: '/dataMn/dataPage',
        children: [
          {
            path: 'dataPage',
            component: DataPage,
            redirect: '/dataMn/dataPage/list',
            children: [
              {
                path: 'list',
                component: DataPageList,
                meta: {
                  title: '数据管理列表'
                }
              },
              {
                path: 'detail',
                component: DataPageDetail,
                meta: {
                  title: '原始数据详情'
                }
              }
            ]
          }
        ]
      },
      {
        path: '/accountMn',
        component: AccountMn,
        meta: {
          title: '账号管理',
          breadNum: 1
        }
      },
      {
        path: '/orderCenter',
        component: OrderCenter,
        name: 'OrderCenter',
        meta: {
          title: '订单管理',
          breadNum: 1
        },
        children: [
          {
            path: '/orderPay',
            component: OrderPay,
            name: 'OrderPay',
            meta: {
              title: '订单支付',
              breadNum: 2
            }
          }
        ]
      },
      {
        path: '/myGroupBuy',
        component: MyGroupBuy,
        name: 'MyGroupBuy',
        meta: { title: '我的拼团', breadNum: 1 }
      },
      {
        path: '/refundList',
        component: RefundList,
        name: 'RefundList',
        meta: {
          title: '退费管理',
          breadNum: 1
        },
        children: []
      },
      {
        path: '/couponCenter',
        component: CouponCenter,
        name: 'CouponCenter',
        meta: {
          title: '领券中心',
          breadNum: 1
        },
        children: []
      },
      {
        path: '/coupon',
        component: Coupon,
        name: 'Coupon',
        meta: {
          title: '优惠券',
          breadNum: 1
        },
        children: []
      },
      {
        path: '/invoiceList',
        component: InvoiceList,
        name: 'InvoiceList',
        meta: {
          title: '发票管理',
          breadNum: 1
        },
        children: [
          {
            path: '/invoiceDetail',
            component: InvoiceDetail,
            name: 'InvoiceDetail',
            meta: {
              title: '发票详情',
              breadNum: 2
            }
          },
          {
            path: '/invoiceAdd',
            component: InvoiceAdd,
            name: 'InvoiceAdd',
            meta: {
              title: '申请发票',
              breadNum: 2
            }
          }
        ]
      },
      {
        path: '/purchaseServices',
        component: PurchaseServices,
        name: 'PurchaseServices',
        meta: {
          title: '购买服务',
          breadNum: 1
        }
      },
      {
        path: '/userInfo',
        component: UserInfo,
        name: 'UserInfo',
        meta: {
          title: '账号管理',
          breadNum: 1
        }
      },
      {
        path: '/wechatPay',
        component: WechatPay,
        name: 'WechatPay',
        meta: {
          title: '微信支付'
        }
      },
      {
        path: '/alipay',
        component: AliPay,
        name: 'AliPay'
      }
    ]
  },
  {
    path: '/top',
    component: TopHeader,
    meta: {
      title: ''
    },
    children: [
      {
        path: '/docsDescription',
        component: DocsDescription,
        name: 'DocsDescription',
        meta: {
          title: '帮助文档',
          breadNum: 1
        }
      },
      {
        path: '/platformAgreement',
        component: PlatformAgreement,
        name: 'PlatformAgreement',
        meta: {
          title: '平台法律声明',
          breadNum: 1
        }
      }
    ]
  }
  // {
  //   path: '/taskMnDetail',
  //   component: TaskMnDetail
  // },
  // {
  //   path: '/configDetail',
  //   component: ConfigDetail
  // },
  // {
  //   path: '/description',
  //   component: Description
  // }
]

export default system
