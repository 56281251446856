export default {
  message: '/api/v1/task/message', // 任务未读消息
  list: {
    url: '/api/v1/task',
    method: 'post'
  },
  delete: {
    url: '/api/v1/task/', // /{taskId}
    method: 'delete'
  }
}
