import Vue from 'vue'
import axios from 'axios'

Vue.directive('tracking', {
  bind: function (el, binding) {
    el.addEventListener('click', () => {
      axios({
        url: '/api/v1/es/cloud/create',
        method: 'post',
        cache: 'no-cache',
        timeout: 60000,
        headers: {
          'Content-Type': 'application/json'
        },
        data: { key: binding.value || '' }
      })
    })
  }
})

// Vue.directive('myDir', {
//   bind (el) {
//     console.log('myDir bind')
//   },
//   inserted () {
//     console.log('myDir inserted')
//   },
//   update () {
//     console.log('myDir update')
//   },
//   componentUpdated () {
//     console.log('myDir componentUpdated')
//   },
//   unbind () {
//     console.log('myDir unbind')
//   }
// })
