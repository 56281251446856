import Cookies from 'js-cookie'

const TokenKey = 'token'
const CurrentTokenKey = 'currentToken'
const userToken = 'userToken'
const LangKey = 'locale'

const domain = process.env.NODE_ENV === 'development' ? '' : '.raymics.com'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token, { domain: domain })
}

export function setUserToken(token) {
  return Cookies.set(userToken, token, { domain: domain })
}

export function getUserToken() {
  return Cookies.get(userToken)
}

export function getCurrentToken() {
  return Cookies.get(CurrentTokenKey)
}

export function setCurrentToken(token) {
  return Cookies.set(CurrentTokenKey, token)
}

export function removeToken() {
  Cookies.remove(CurrentTokenKey)
  Cookies.remove(TokenKey, { domain: domain })
  Cookies.remove(userToken, { domain: domain })
}

export function getLang() {
  return Cookies.get(LangKey)
}

export function setLang(lang) {
  return Cookies.set(LangKey, lang)
}

export function removeLang() {
  return Cookies.remove(LangKey)
}
