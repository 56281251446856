export default {
  list: '/api/v1//featureenginechain/list', // 链路列表
  add: '/api/v1/featureenginechain', // 保存链路 post
  featureenginechainPublic: '/api/v1/featureenginechain/', // {featureenginechainId} 修改put  删除 delete
  save: '/api/v1/featureengine', // 项目-特征工程-新建、复制 post
  getList: '/api/v1/featureengine/list', // 项目-特征工程-默认链路列表 post
  featureenginePublic: '/api/v1/featureengine/', // {featureengineId} 项目-特征工程-修改名称 put 删除 delete
  delPre: '/api/v1/featureengine/pre/', // featureengineId} 项目-特征工程-删除 前置校验 get
  updataChain: '/api/v1/featureengine/chain', // 项目-特征工程-修改链路后保存链路 post
  resume: '/api/v1/featureengine/resume/', // featureengineId} 项目-特征工程-恢复默认链路 put
  preresume: '/api/v1/featureengine/preresume/', // featureengineId} 项目-特征工程-恢复默认链路 前置校验 get
  chainlist: '/api/v1/featureengine/options/chainlist', // 项目-特征工程-获取可选链路列表 post
  start: 'api/v1/featureengine/start', // 项目-特征工程-运行特征工程 post
  cancel: 'api/v1/featureengine/cancel', // 项目-特征工程-取消特征工程 post
  getReportInfo: 'api/v1/featureengine/', // + {featureengineId} + /report 项目-特征工程-查看特征工程报告 get
  downloadXlsxZip: 'api/v1/report/download/projfe/', // + {projectId}/{feId} 项目-特征工程-下载特征数据(根据项目id和特征id去获取报告信息) get
  downloadResult: 'api/v1/featureengine/download/', // + {featureengineId}/result 项目-特征工程-下载特征工程结果 get
  errMsg: 'api/v1/featureengine/' // {featureengineId}/errmsg 项目-特征工程-查看失败原因 get
}
