export default {
  userUpdate: 'api/v1/user/update', // 更新用户信息-（Email、工作到单位、职业）
  getVersion: 'api/v1/user/getversion', // 获取版本号 get
  getqr: 'api/v1/user/wx/getqr', // 获取微信登录二维码 get
  bindWx: 'api/v1/user/wx/bind', // 绑定微信 post
  wxLogin: 'api/v1/user/wx/login', // 微信登录 post
  wxbindphone: 'api/v1/user/wx/bindphone', // 微信绑定手机号 post
  createurl: 'api/v1/user/createurl', // 获取头像上传url post
  uploadavatar: 'api/v1/user/uploadavatar', // 上传头像 post
  login: {
    url: '/api/v1/user/login/passwd', // 账号/手机号&&密码 登录
    method: 'post'
  },
  register: {
    url: '/api/v1/user/register',
    method: 'post'
  },
  regsms: {
    url: '/api/v1/user/regsms', // 注册验证码
    method: 'post'
  },
  loginSendsms: {
    url: '/api/v1/user/login/sendsms', // 登录验证码
    method: 'post'
  },
  loginSms: {
    url: '/api/v1/user/login/sms', // 短信登录
    method: 'post'
  },
  logout: {
    url: '/api/v1/user/signout',
    method: 'post'
  },
  refresh: {
    url: '/api/v1/user/refrash',
    method: 'post'
  },
  userInfo: {
    url: '/api/v1/user/info',
    method: 'get'
  },
  regemail: {
    url: '/api/v1/user/regemail',
    method: 'post'
  },
  reset: {
    url: '/api/v1/user/reset',
    method: 'post'
  },
  changepassword: {
    url: '/api/v1/user/changepasswd', // 修改密码
    method: 'put'
  },
  updateSms: 'api/v1/user/update/sendsms', // 更新用户资料短信
  verify: 'api/v1/user/sms/verify', // 短信确认---验证手机号和code
  changephoneVerify: 'api/v1/user/changephone/verify', // 修改手机-验证手机号和code
  changephoneSendsms: {
    url: '/api/v1/user/changephone/sendsms', // 更换手机号 发送验证码
    method: 'post'
  },
  changephoneReset: {
    url: '/api/v1/user/changephone/reset', // 更换手机号
    method: 'post'
  },
  forgetpwdSendsms: {
    url: '/api/v1/user/forgetpasswd/sendsms', // 忘记密码 验证码
    method: 'post'
  },
  forgetpwdVerify: {
    url: '/api/v1/user/forgetpasswd/verify', // 忘记密码 验证手机号和code
    method: 'post'
  },
  forgetpassword: {
    url: '/api/v1/user/forgetpasswd/reset', // 忘记密码重置
    method: 'post'
  },
  commitmentRead: 'api/v1/user/commitment/read', //     method: 'put' 用户勾选承诺书等协议后调用此方法
  userList: {
    url: '/api/v1/admin/list',
    method: 'post'
  },
  approve: {
    url: '/api/v1/admin/approve',
    method: 'post'
  },
  getSpace: {
    url: '/api/v1/order/userspace', // 获取用户项目空间使用情况
    method: 'get'
  },
  getUsedSpaceList: '/api/v1/order/space/list', // 获取用户空间过期时间 post
  bannerList: '/api/v1/banner/list' // 获取首页banner get
}
