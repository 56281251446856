<template>
  <div class="h-100-topHeight">
    <div class="nav-tab-box" v-if="showNavTab">
      <div class="nav-tab-box-content">
        <slot name="nav-tab"></slot>
      </div>
    </div>
    <div v-if="showLeftMenu" class="left-menu">
      <slot name="left-menu"></slot>
    </div>
    <section class="section-topHeight">
      <slot></slot>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    backAlert: {
      type: Boolean,
      default: false
    },
    showNavTab: {
      type: Boolean,
      default: true
    },
    showLeftMenu: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.h-100-topHeight {
  // height: calc(100vh - 60px);
  .section-topHeight {
    padding-top: 0;
  }
}
</style>
