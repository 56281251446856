<template>
  <section ref="dialog" class="sec-dialog">
    <el-dialog
      :title="title"
      :visible="visible"
      :width="width"
      v-bind="$attrs"
      v-on="$listeners"
      class="box-el-dialog"
    >
      <div ref="wrap" class="wrap" :style="getStyle">
        <!-- 没有名称, 兼容element-dialog -->
        <slot />
        <slot name="content" />
      </div>
      <span slot="footer" class="dialog-footer">
        <slot name="footer-content" />
      </span>
    </el-dialog>
  </section>
</template>

<script>
import $ from 'jquery'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '85%'
    },
    marBot: {
      type: String,
      default: '10'
    }
  },
  data() {
    return {
      dialogHeight: ''
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.calcHeight()
      }
    }
  },
  computed: {
    getStyle() {
      return {
        '--margin': this.marBot + 'px'
      }
    }
  },
  mounted() {
    this.__resizeHandler = this.$core.debounce(() => {
      if (this.visible) {
        this.calcHeight()
      }
    }, 100)
    window.addEventListener('resize', this.__resizeHandler)
    this.__resizeHandler()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.__resizeHandler)
  },
  methods: {
    calcHeight() {
      this.$nextTick(() => {
        const bodyHeight = $('body').height()
        const paddingTop = 50
        const paddingBottom = 50
        const maxHeight = bodyHeight - paddingTop - paddingBottom
        const bodyMaxHeight = maxHeight - 140
        $('.el-dialog')
          .find('.el-dialog__body')
          .css({
            maxHeight: bodyMaxHeight - 100
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-el-dialog {
  ::v-deep .el-dialog {
    transform: translate(-50%, -50%);
    position: absolute !important;
    top: 50%;
    left: 50%;
    margin-top: 0 !important;
    margin-top: 0px;
    overflow: hidden;
    box-sizing: border-box;
    font-size: 14px;
    color: #888888;
    margin-left: 32px;
    &__title {
      font-weight: bold;
    }
    &__header {
      padding: 20px;
      line-height: 22px;
    }
    &__body {
      overflow-y: auto;
      margin-top: 0;
    }
    &__footer {
      margin-top: 0;
    }
    .wrap {
      padding-bottom: var(--margin);
    }
  }
  @media screen and (max-width: 1365px) {
    ::v-deep .el-dialog {
      margin-left: 0;
    }
  }
}
</style>
