import Vue from 'vue'
import apiCode from '@/api/apiCode'
import apiUrl from '@/api/resetfulApi/config'

const state = {
  showStepBox: false,
  toShowGiftDialog: false,
  openByHeader: false,
  showGuideVideo: false, // 是否显示可拖动弹窗
  guideIndex: 0, // 可拖动弹窗引导索引
  videoIndex: 0, // 视频索引
  showGuideDialog: false, // 展示引导弹窗，接口返回
  popupClosed: false, // 关闭引导弹窗，存储到sessionStorage，用于本次登录后不再弹出
  guideSequence: [], // 引导顺序数组
  currentGuideIndex: 0, // 当前光标引导索引
  isGuideProject: false
}

// 顶部引导和视频map（视频有重复）
const videoMap = new Map([
  [0, 0],
  [1, 1],
  [2, 2],
  [3, 3],
  [4, 1],
  [5, 2],
  [6, 4]
])
const mutations = {
  setShowStepBox(state, val) {
    state.showStepBox = val
  },
  receiveGiftPack(state, val) {
    state.toShowGiftDialog = val
  },
  setOpenByHeader(state, bool) {
    state.openByHeader = bool
  },
  setShowGuideVideo(state, bool) {
    state.showGuideVideo = bool
  },
  setGuideIndex(state, index) {
    state.guideIndex = index
    state.videoIndex = videoMap.get(index)
  },
  setShowGuideDialog(state, bool) {
    state.showGuideDialog = bool
  },
  setPopupClosed(state, popupClosed) {
    // 关闭首页引导弹窗
    state.popupClosed = popupClosed
  },
  SET_GUIDE_SEQUENCE(state, sequence) {
    state.guideSequence = sequence
  },
  INCREMENT_GUIDE_INDEX(state) {
    state.currentGuideIndex++
  },
  RESET_GUIDE_INDEX(state) {
    state.currentGuideIndex = 0
  },
  SET_IS_GUIDE_PROJECT(state, bool) {
    state.isGuideProject = bool
  }
}

const actions = {
  fetchGuide({ commit }) {
    // 接口请求获取 guide_flag, steps 值
    return new Promise((resolve, reject) => {
      Vue.prototype
        .$get(apiUrl.guide)
        .then((res) => {
          if (res.code === apiCode.success) {
            const noLongerShow = res.data.guide_flag === 1
            const stepsStr = res.data.steps || '[]'
            const steps = JSON.parse(stepsStr)
            if (noLongerShow) {
              commit('setShowGuideDialog', false)
              commit('SET_GUIDE_SEQUENCE', steps)
            } else {
              commit('setShowGuideDialog', true)
            }
            resolve()
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // 更新引导步骤和是否展示弹窗
  updateGuideSequence({ commit }, query) {
    return new Promise((resolve, reject) => {
      Vue.prototype
        .$update(apiUrl.updateGuide, query)
        .then((res) => {
          if (res.code === apiCode.success) {
            resolve()
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  // 开始下一步引导
  startNextGuide({ commit, state, dispatch }) {
    if (state.currentGuideIndex < state.guideSequence.length - 1) {
      commit('INCREMENT_GUIDE_INDEX')
    }
  },

  // 结束引导
  async endGuide({ commit, state, dispatch }) {
    dispatch('startNextGuide')
    const updatedSequence = state.guideSequence.slice(1)
    const updatedSequenceStr = JSON.stringify(updatedSequence)
    commit('SET_GUIDE_SEQUENCE', updatedSequence)

    const resetSteps = false
    if (resetSteps) {
      await dispatch('updateGuideSequence', {
        steps: '',
        guide_flag: 0
      })
    } else {
      await dispatch('updateGuideSequence', {
        steps: updatedSequenceStr,
        guide_flag: 1
      })
    }

    commit('RESET_GUIDE_INDEX')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
