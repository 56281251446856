import Vue from 'vue'
import { MessageBox } from 'element-ui'

let title = ''
class Element {
  // 确认 取消框
  confirm(msg, confirmButtonText = '确定', cancelButtonText = '取消') {
    if (typeof msg !== 'string') {
      title = msg.title
      msg = msg.msg
    }
    return new Promise((resolve) => {
      MessageBox.confirm(msg, title, {
        cancelButtonText: cancelButtonText,
        confirmButtonText: confirmButtonText,
        type: 'warning',
        lockScroll: false
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  alert(msg, confirmButtonText = '确定') {
    if (typeof msg !== 'string') {
      title = msg.title
      msg = msg.msg
    }
    return new Promise((resolve) => {
      MessageBox.alert(msg, title, {
        confirmButtonText: confirmButtonText,
        dangerouslyUseHTMLString: true
      })
        .then(() => {
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }
}

Vue.prototype.$element = new Element()
