export default {
  info: {
    url: '/api/v1/radiomics/info', // radiomics 配置详情
    method: 'post'
  },
  getList: {
    url: '/api/v1/radiomics',
    method: 'get'
  },
  update: {
    url: '/api/v1/radiomics/update',
    method: 'post'
  }
}
