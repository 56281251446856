export default {
  list: '/api/v1/original/list', // post 原始数据集列表 post
  allList: '/api/v1/original/all', // 原始数据集列表-全部
  add: '/api/v1/original', // 新增原始数据集 post
  pre: '/api/v1/original/pre/', // delete 数据集删除前置校验{originalId}
  originalPublic: '/api/v1/original/', // 原始数据集详情、更新、删除{originalId} get put delete
  getEda: '/api/v1/original/eda/', // {originalId} 获取原始数据集eda信息 get
  clinicaldataList: '/api/v1/original/eda/clinicaldata/list', // 获取临床数据列表post
  clinicaldataInfo: '/api/v1/original/eda/clinicaldata/info', // {originalId} 获取临床数据列表详细信息get
  clinicaldataUpdate: '/api/v1/original/eda/clinicaldata/update', // 修改临床数据post
  standardization: '/api/v1/original/eda/standardization', // 标准化post && 获取标准化信息get
  standardizationDoing: '/api/v1/original/eda/standardization/doing', // post 执行标准化
  standardfields: '/api/v1/standardfields', // post 新建标准字段
  standardfieldsList: '/api/v1/standardfields/list' // post 标准字段列表
}
