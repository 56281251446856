export default {
  demoProList: 'api/v1/project/demolist', // 示例项目列表 get
  demoDatasetlist: 'api/v1/datamgmt/offical/dataset', // 示例数据集列表 get
  proimport: 'api/v1/project/import', //  导入示例项目 get
  proexport: 'api/v1/project/export/', //  {{project_id}} 导入示例项目 get
  proimportrecords: 'api/v1/project/importrecords', // 导入项目记录 get
  datasetimport: 'api/v1/datamgmt/offical/dataset/import', // 导入示例数据集 get
  datasetimportrecords: 'api/v1/datamgmt/offical/dataset/import/history', // 导入数据集记录 get

  isFirst: 'api/v1/project/isfirst', // 第一次查看项目详情 get
  list: 'api/v1/project/list', // 项目列表 post
  add: 'api/v1/project', // 创建项目 post
  clinicdatas: 'api/v1/project/clinicdatas/', // {datasetId} 获取临床数据
  projectPublic: 'api/v1/project/', // {projectId} 项目详情 get 删除 delete 修改名称 put
  dataprepareTable: 'api/v1/dataprepare/table/', // {{dataprepareId}} get 预处理EDA 预览前25条数据
  proCancel: 'api/v1/project/cancel', // post 一键训练运行 中止
  proTasks: 'api/v1/project/tasks', // post 项目内任务列表信息
  getIncommonusePro: 'api/v1/project/list/incommonuse', // get 获取常用项目
  validatemulti: 'api/v1/project/validatemulti', // 校验多模态数据集的训练集和测试集是否匹配
  multiRelation: 'api/v1/project/multirelation/' // 多模态子数据集映射关系表
}
