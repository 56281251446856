const apiCode = {
  success: 20000, // 成功
  authFail: 30704, // 非法的头部验证请求
  expireTime: 30705, // 非法的标签头部请求-- cookie失效
  locked: 30402, // 用户被删除后，不存在
  serverFail: 30500, // 服务器连接失败
  tokenFail: 40003, // 用户中心 登录token失效
  expireTimeCanRefresh: 40004 // 用户中心  登录token超时 可以刷新token登陆
}

export default apiCode
