import Vue from 'vue'

Vue.mixin({
  methods: {
    dataVerification (obj) {
      for (const key in obj) {
        // eslint-disable-next-line
        if (obj.hasOwnProperty(key)) {
          const element = obj[key]
          if (!element || element === '') {
            obj[key] = null
          }
        }
      }
    },
    // 获取对象中相应属性的值
    getValueFromObj (obj, property) {
      for (const key in obj) {
        // eslint-disable-next-line
        if (obj.hasOwnProperty(key)) {
          if (key === property) {
            return obj[key]
          }
        }
      }
      return '--'
    },
    // 表单校验
    getValidate (obj) {
      let isValidate = true
      this.$refs[obj].validate((valid) => {
        if (valid) {
          isValidate = true
        } else {
          isValidate = false
        }
      })
      return isValidate
    },
    // 输入框验证
    validateInput (rule, value, callback, label) {
      const regexp = rule
      const isValidate = regexp.test(value)
      if (isValidate) {
        callback()
      } else {
        callback(new Error(label))
      }
    }
  }
})
