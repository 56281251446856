import axios from 'axios'
import router from '@/router'
import apiCode from '@/api/apiCode'
import { MessageBox } from 'element-ui'
import { getToken, removeToken, getCurrentToken } from '@/utils/auth'
import store from '@/store'
import qs from 'qs'

// const root = window.location.origin
// const request = axios.create({
//   // 请求的根路径
//   baseURL: root
// })

const MAX_REQUESTS_COUNT = 5 // 同时最大请求数量
const INTERVAL_MS = 10 // 多余请求 发起请求间隔时间
let PENDING_REQUESTS = 0 // pedding状态等待数量

// axios.defaults.withCredentials = true // 允许携带cookie
// axios.defaults.timeout = 60000 // 超时时间
// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (config.headers.Authorization === 'none') {
      config.headers.Authorization = ''
    } else {
      if (getToken()) {
        config.headers.Authorization = `Bearer ${getToken()}`
      } else if (getCurrentToken()) {
        config.headers.Authorization = `Bearer ${getCurrentToken()}`
      }
    }
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      }
    }
    const data = config.data || {}
    config.data = data
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++
          clearInterval(interval)
          resolve(config)
        }
      }, INTERVAL_MS)
    })
  },
  async error => {
    return Promise.reject(error)
  }
)

// 是否正在刷新的标记
// const isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
// const requests = []
let alertFLg = false
// 响应拦截器
axios.interceptors.response.use(
  response => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    const downloadHeader = response.headers['content-disposition']
    if (!downloadHeader) {
      if (response.status === 200 && response.data) {
        const data = response.data
        const arr = [
          apiCode.authFail, // 权限认证失败
          apiCode.expireTime, // 非法的标签头部请求-- cookie失效
          apiCode.locked, // 用户被锁定
          apiCode.tokenFail, // 用户中心 登录token失效
          apiCode.expireTimeCanRefresh // 用户中心  登录token超时 可以刷新token登陆
        ]
        // 请求失败
        const code = data.code
        if (
          code === 40004 ||
          (code === -1 &&
            data.msg === 'unknown code 您的账号在其它设备上已登入，将被强制下线')
        ) {
          if (window.location.hash !== '#/pages/system/login') {
            if (!alertFLg) {
              store.commit('SET_TOKEN', '')
              store.commit('guide/setShowGuideVideo', false) // 引导视频弹窗关闭
              store.commit('guide/setOpenByHeader', false) // 引导视频打开方式重置为 false
              removeToken()
              localStorage.clear()
              sessionStorage.clear()
              router.push('/pages/system/login')
              MessageBox.alert(
                '您的账号在其它设备上已登入，将被强制下线',
                '账号异常提示',
                {
                  confirmButtonText: '确定',
                  callback: action => {
                    store.commit('SET_TOKEN', '')
                    store.commit('user/setUserId')
                    removeToken()
                    localStorage.clear()
                    sessionStorage.clear()
                    const url = window.location.origin
                    window.location.replace(url + '#/pages/system/login')
                    window.location.reload()
                  }
                }
              )
              alertFLg = true
            }
          }

          // const config = response.config
          // if (!isRefreshing) {
          //   isRefreshing = true
          //   return store
          //     .dispatch('user/resetToken')
          //     .then((res) => {
          //       console.log(res, 'refresh res')
          //       const token = getToken() ? getToken() : getCurrentToken()
          //       config.headers.Authorization = 'Bearer ' + token
          //       console.log(requests, 'requests111')
          //       requests.forEach((cb) => cb(token))
          //       // 重试完了别忘了清空这个队列
          //       requests = []
          //       isRefreshing = false
          //       return request(config)
          //     })
          //     .catch((res) => {
          //       console.error('refreshtoken error =>', res)
          //       const env = process.env.NODE_ENV
          //       if (env === 'development') {
          //         window.location.href = 'http://localhost:10000/#/pages/system/login'
          //       } else {
          //         const origin = window.location.origin
          //         window.location.href = origin + '/#/pages/system/login'
          //       }
          //     })
          //     .finally(() => {
          //       isRefreshing = false
          //     })
          // } else {
          //   // 正在刷新token，返回一个未执行resolve的promise
          //   return new Promise((resolve) => {
          //     console.log(requests, 'requests222')
          //     // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          //     requests.push((token) => {
          //       config.headers.Authorization = 'Bearer ' + token
          //       resolve(request(config))
          //     })
          //   })
          // }
        } else if (arr.includes(code)) {
          store.commit('SET_TOKEN', '')
          store.commit('user/setUserId')
          removeToken()
          localStorage.clear()
          sessionStorage.clear()
          if (code !== 30402) {
            // 登录页面-用户不存在-不需要刷新
            const url = window.location.origin
            window.location.replace(url + '#/pages/system/login')
            window.location.reload()
          }
        } else alertFLg = false
      }
    }
    return response
  },
  error => {
    console.log(error, 'error')
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  }
)
