import Vue from 'vue'
import dayjs from 'dayjs'

// 字节大小单位
Vue.filter('fmtByte', (val) => {
  if (!val) {
    return '0B'
  }
  const num = 1024.0 // byte
  if (val < num) {
    return val + 'B'
  }
  if (val < Math.pow(num, 2)) {
    return (val / num).toFixed(2) + 'K'
  } // kb
  if (val < Math.pow(num, 3)) {
    return (val / Math.pow(num, 2)).toFixed(2) + 'M'
  } // M
  if (val < Math.pow(num, 4)) {
    return (val / Math.pow(num, 3)).toFixed(2) + 'G'
  } // G
  return (val / Math.pow(num, 4)).toFixed(2) + 'T' // T
})

// 时间戳转为日期
Vue.filter('fmtDate', (val, fmt = 'YYYY-MM-DD') => {
  val = val ? dayjs(val).format(fmt) : '--'
  return val
})
// 时间戳转日期时间
Vue.filter('fmtDateTime', (val, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  val = val ? dayjs(val).format(fmt) : '--'
  return val
})

// (2019-01-01T15:59:50.000+0000) 时区日期时间转正常日期时间
Vue.filter('fmtTime', (val, fmt = 'YYYY-MM-DD HH:mm:ss') => {
  val = val ? dayjs(val).format(fmt) : ''
  return val
})
// (2019-01-01T15:59:50.000+0000) 时区日期时间转正常日期时间
Vue.filter('fmtTimeSeconds', (val, fmt) => {
  if (!val) return '--'
  const str = String(val)
  let neg = false // 是否为负数
  if (str.includes('-')) {
    neg = true
    val = str.substring(1)
  }
  let theTime = parseInt(val) // 秒
  let theTime1 = 0 // 分
  let theTime2 = 0 // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60)
    theTime = parseInt(theTime % 60)
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60)
      theTime1 = parseInt(theTime1 % 60)
    }
  }
  let result = ''

  if (parseInt(theTime) > 0) {
    result = '' + parseInt(theTime) + 's'
  }
  if (theTime1 > 0) {
    result = '' + parseInt(theTime1) + 'm' + result
  }
  if (theTime2 > 0) {
    result = '' + parseInt(theTime2) + 'h' + result
  }

  return neg ? '-' + result : result
})

// 手机号中间四位隐藏
Vue.filter('hidePhone', (val) => {
  val = val ? val.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3') : '--'
  return val
})
// 空值显示为--
Vue.filter('across', (val) => {
  return val || '--'
})
// 千分位
Vue.filter('thousand', (val) => {
  return (val.toFixed(2) + '').replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
})
// 阿拉伯数字转人民币的中文
Vue.filter('toRMB', (money) => {
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const cnIntRadice = ['', '拾', '佰', '仟']
  const cnIntUnits = ['', '万', '亿', '兆']
  const cnDecUnits = ['角', '分', '毫', '厘']
  const cnInteger = '整'
  const cnIntLast = '元'
  let IntegerNum
  let DecimalNum
  let ChineseStr = ''
  let parts
  if (money === '') {
    return ''
  }
  money = parseFloat(money)
  if (money === 0) {
    ChineseStr = cnNums[0] + cnIntLast + cnInteger
    return ChineseStr
  }
  money = money.toString()
  if (money.indexOf('.') === -1) {
    IntegerNum = money
    DecimalNum = ''
  } else {
    parts = money.split('.')
    IntegerNum = parts[0]
    DecimalNum = parts[1].substr(0, 4)
  }
  if (parseInt(IntegerNum, 10) > 0) {
    let zeroCount = 0
    const IntLen = IntegerNum.length
    for (let i = 0; i < IntLen; i++) {
      const n = IntegerNum.substr(i, 1)
      const p = IntLen - i - 1
      const q = p / 4
      const m = p % 4
      if (n === '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          ChineseStr += cnNums[0]
        }
        zeroCount = 0
        ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m === 0 && zeroCount < 4) {
        ChineseStr += cnIntUnits[q]
      }
    }
    ChineseStr += cnIntLast
  }
  if (DecimalNum !== '') {
    const decLen = DecimalNum.length
    for (let j = 0; j < decLen; j++) {
      const n1 = DecimalNum.substr(j, 1)
      if (n1 !== '0') {
        ChineseStr += cnNums[Number(n1)] + cnDecUnits[j]
      }
    }
  }
  if (ChineseStr === '') {
    ChineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (DecimalNum === '') {
    ChineseStr += cnInteger
  }
  return ChineseStr
})
