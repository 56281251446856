<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepalive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepalive"></router-view>
    <GuideVideo v-if="showGuideVideo" />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getToken, getCurrentToken } from '@/utils/auth'
import GuideVideo from '@/components/GuideVideo.vue'
export default {
  components: { GuideVideo },
  data() {
    return {
      aaa: ''
    }
  },
  computed: {
    ...mapState({
      userId: (state) => state.user.userId,
      showGuideVideo: (state) => state.guide.showGuideVideo
    })
  },
  mounted() {
    if (this.userId) {
      this.getUserData()
    } else {
      if (getToken() || getCurrentToken()) this.getUserData()
    }
  },
  methods: {
    async getUserData() {
      const data = await this.$get(this.$api.login.userInfo.url)
      if (data.code === this.$apiCode.success) {
        const res = data.data
        this.$store.commit('user/setUserId', res.user_id)
      }
    }
  }
}
</script>

<style lang="scss">
@import './styles/base.scss';
@import './styles/core.scss';
@import './styles/reset.css';
#app {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  height: 100%;
}
</style>
