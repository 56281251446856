import Vue from 'vue'
import apiCode from '@/api/apiCode'

class Core {
  constructor() {
    this.ls = localStorage
    this.ss = sessionStorage
  }

  // 添加localStorage
  setLocal(key, value) {
    let v = value
    v = typeof v === 'object' ? 'obj-' + JSON.stringify(v) : 'str-' + v
    this.ls.setItem(key, v)
  }

  // 获取localStorage
  getLocal(key) {
    let v = this.ls.getItem(key)
    if (v) {
      v = this.getStorageValue(v)
      return v
    }
  }

  // 添加session
  setSession(key, value) {
    let v = value
    v = typeof v === 'object' ? 'obj-' + JSON.stringify(v) : 'str-' + v
    this.ss.setItem(key, v)
  }

  // 获取session
  getSession(key) {
    let v = this.ss.getItem(key)
    if (v) {
      v = this.getStorageValue(v)
      return v
    }
  }

  // 清空某个localStorage
  rmLocal(key) {
    key && this.ls.removeItem(key)
  }

  // 清空所有localStorage
  clearLocal() {
    this.ls.clear()
  }

  // 清空某个session
  rmSession(key) {
    key && this.ss.removeItem(key)
  }

  // 清空所有session
  clearSession() {
    this.ss.clear()
  }

  // 获取值
  getStorageValue(v) {
    if (v.indexOf('obj-') === 0) {
      v = v.slice(4)
      return JSON.parse(v)
    } else if (v.indexOf('str-') === 0) {
      return v.slice(4)
    }
  }

  // 获取url参数
  getUrlParam(url) {
    const urlObject = {}
    if (/\?/.test(url)) {
      const urlString = url.substring(url.indexOf('?') + 1)
      const urlArray = urlString.split('&')
      for (let i = 0, len = urlArray.length; i < len; i++) {
        const urlItem = urlArray[i]
        const item = urlItem.split('=')
        urlObject[item[0]] = item[1]
      }
      return urlObject
    }
  }

  // 对象数组去重
  distinct(arr, field = 'id') {
    const res = new Map()
    return arr.filter(
      (item) => !res.has(item[field]) && res.set(item[field], 1)
    )
  }

  // 简单数组去重
  sole(arr) {
    return Array.from(new Set(arr))
  }

  // 克隆
  clone(val) {
    return JSON.parse(JSON.stringify(val))
  }

  // 判断接口是否成功
  isSuccess(ret) {
    const code = ret?.code
    return code === apiCode.success
  }

  // 图片预加载
  async preloadImages(imageUrls) {
    const promises = imageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = resolve
        image.onerror = reject
        image.src = url
      })
    })
    await Promise.all(promises)
  }

  // 是否为空
  isEmpty(data) {
    if (data === 0) {
      return false
    }
    if (!data) {
      return true
    }
    if (Array.isArray(data) && !data.length) {
      return true
    }
    if (typeof data === 'object') {
      for (const item in data) {
        if (data[item] || data[item] === 0) {
          return false
        }
      }
    }
    return true
  }

  // 获取dom
  dom(el) {
    return document.querySelector(el)
  }

  // 防抖
  debounce(fn, time = 300) {
    let timeout = null
    return function () {
      const context = this
      const args = arguments
      timeout !== null && clearTimeout(timeout)
      timeout = setTimeout(function () {
        fn.apply(context, args)
      }, time)
    }
  }

  // 节流
  throttle(fn, time = 300) {
    let timer = null
    return function () {
      if (!timer) {
        timer = setTimeout(function () {
          fn()
          timer = null
        }, time)
      }
    }
  }
}

Vue.prototype.$core = new Core()

export default new Core()
