const getters = {
  showTips: (state) => state.task.showTips,
  taskFlg: (state) => state.task.taskFlg,
  language: (state) => state.user.language,
  username: (state) => state.user.username,
  token: (state) => state.user.token,
  avatar: (state) => state.user.avatar
}

export default getters
