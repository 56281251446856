const state = {
  dataActiveTab: 0
}
const mutations = {
  setDataActiveTab(state, val) {
    state.dataActiveTab = val
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
