import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Cookies from 'js-cookie'

// 模块引用
import ElementUI from 'element-ui'
import './styles/element-variables.scss'
// import i18n from '@/lang' // Internationalization
// 文件引用
import '@/utils/import'
import XnrDialog from '@/components/Dialog'
import api from '@/api/resetfulApi'
import apiCode from '@/api/apiCode'
import regexp from '@/utils/regexp'
import Clipboard from 'vue-clipboard2'
import MainBox from '@/components/MainBox'
import ErrorDialog from '@/components/errorDialog'

Vue.use(Clipboard)
Vue.prototype.$api = api
Vue.prototype.$apiCode = apiCode
Vue.prototype.$regexp = regexp
Vue.prototype.$docsUrl = process.env.VUE_APP_DOCS_URL // 帮助文档 && 法律文档cdn 地址
// dialog注册为全局组件
Vue.component('XnrDialog', XnrDialog)

Vue.component('MainBox', MainBox)
Vue.component('ErrorDialog', ErrorDialog)
// 插件使用
Vue.use(ElementUI, { size: 'large' })

// Vue.use(Element, {
//   size: Cookies.get('size') || 'medium', // set element-ui default size
//   i18n: (key, value) => i18n.t(key, value)
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // i18n,
  render: (h) => h(App)
}).$mount('#app')
