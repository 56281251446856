import apiCode from '@/api/apiCode'
import Vue from 'vue'
import apiUrl from '@/api/resetfulApi/signedProject'
const state = {
  signProjList: []
}
const mutations = {
  setSignProjList(state, val) {
    state.signProjList = val
  }
}

const actions = {
  getSignProj({ commit }) {
    return new Promise((resolve, reject) => {
      Vue.prototype
        .$get(apiUrl.signlist)
        .then((res) => {
          if (res.code === apiCode.success) {
            const list = res.data.list || []
            commit('setSignProjList', list)
            resolve(list)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
