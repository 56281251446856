const FreeForMembers = () => import('@/views/freeForMembers/index.vue')

const FreeForMembersRoutes = [
  {
    path: '/freeForMembers',
    name: 'freeForMembers',
    component: FreeForMembers
  }
]
export default FreeForMembersRoutes
